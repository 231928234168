import { Injectable } from '@angular/core';
import { LoggerService, Logger } from '@core/services/logger/logger.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { RootState } from './root.state';

@Injectable()
export class CurrentStateService {
  private logger: Logger;

  constructor(
    private store: Store<RootState>,
    private loggerService: LoggerService,
  ) {
    this.logger = this.loggerService.getLogger('CurrentStateService');
  }

  public get<T>(selectorFn: (state: any, params?: any) => T, params?: any): T | undefined {
    let result: T | undefined;

    try {
      this.store
        .select(selectorFn, params)
        .pipe(first())
        .subscribe((res: T) => (result = res));
    } catch {
      this.logger.error('CurrentStateService.get: Failed selecting substate from store');
    }

    return result;
  }

  public select<T, P>(selector: (state: RootState, params?: P) => T): Observable<T> {
    return this.store.select(selector);
  }
}
