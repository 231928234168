
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppRoute } from '@app/app-routes.enum';
import { WindowRefService } from '../window-ref/window-ref.service';
import { letItSnow, SNOW_CANVAS_ID } from './let-it-snow';

@Injectable()
export class SpecialEditionService {
  constructor(
    private windowRefService: WindowRefService,
    private router: Router,
  ) {}

  public initialize(): void {
    if (this.isTimeForWinterEdition) {
      this.enableWinterEdition();
    }
  }

  private get isTimeForWinterEdition(): boolean {
    return [ 0, 11 ].includes(this.currentMonth); // checking for january and december
  }

  private get currentMonth(): number {
    return new Date().getMonth();
  }

  private enableWinterEdition(): void {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        try {
          const SNOW_DISPLAY_MODE = e.urlAfterRedirects.includes(AppRoute.DASHBOARD) ? 'block' : 'none';
          this.windowRefService.nativeDocument.getElementById(SNOW_CANVAS_ID).style.display = SNOW_DISPLAY_MODE;
        } catch {
          // :(
        }
      });

    try {
      letItSnow();
    } catch {
      // :(
    }
  }
}
