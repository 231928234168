import { AppRoute } from '@app/app-routes.enum';
import { ProductType } from '@core/types/types';
import { DocumentScope as SSSDShopName } from '@generated/sssd/models/document-scope';
import { Shop } from '@generated/sssp-v2/models';

export const API_APP_NAME = 'sss';
export const APP_NAME = 'SellerCenter';

// Currency Code ISO 4217
export enum CurrencyCode {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  AUD = 'AUD',
  ZAR = 'ZAR',
  KRW = 'KRW',
  CHF = 'CHF',
  JPY = 'JPY'
}

export const DEFAULT_CURRENCY_LOCALE = {
  [CurrencyCode.KRW]: 'ko-KR',
  [CurrencyCode.ZAR]: 'en-ZA',
  [CurrencyCode.AUD]: 'en-AU',
  [CurrencyCode.EUR]: 'de-DE',
  [CurrencyCode.GBP]: 'en-UK',
  [CurrencyCode.USD]: 'en-US',
  [CurrencyCode.CHF]: 'de-CH',
  [CurrencyCode.JPY]: 'ja-JP',
};

export type SellerCenterCurrencyIso = keyof typeof CurrencyCode;

export enum SellerCenterMarket {
  DE = 'DE',
  AT = 'AT',
  AU = 'AU',
  CH = 'CH',
  GB = 'GB',
  FR = 'FR',
  SE = 'SE',
  IT = 'IT',
  NL = 'NL',
  BE = 'BE',
  PT = 'PT',
  ZA = 'ZA',
  LU = 'LU', // country just for MARS
  KR = 'KR',
  JP = 'JP',
}

export enum LanguageCode {
  DE = 'de',
  EN = 'en',
  IT = 'it',
  FR = 'fr',
  PT = 'pt',
  NL = 'nl',
  SV = 'sv',
  KO = 'ko',
  JA = 'ja',
}

export enum ProductCategory {
  SERVICE_CONTRACTS = 'SERVICE_CONTRACTS',
  GENERAL = 'GENERAL',
  ACCESSORIES = 'ACCESSORIES',
  NCOS = 'NCOS',
  UCOS = 'UCOS',
  TYRES = 'TYRES',
  CONNECT = 'CONNECT',
  WALLBOX = 'WALLBOX',
  WARRANTY_PACKAGE = 'WARRANTY_PACKAGE',
  COMPLETE_CARE = 'COMPLETE_CARE',
}


export enum DecimalSeparators {
  Default = '.',
  DE = ',',
}

// export enum SellerCenterShopName {
//   ACCESSORIES = 'ACCESSORIES',
//   COLLECTION = 'COLLECTION',
//   CONNECT = 'CONNECT',
//   NCOS = 'NCOS',
//   // MARKETPLACE = 'MARKETPLACE',
//   UCOS = 'UCOS',
//   GPME = 'GPME',
// }

export import SellerCenterShopName = Shop;

export enum SellerCenterDomain {
  DASHBOARD = 'DASHBOARD',
  PROFILE = 'PROFILE',
  PRICING = 'PRICING',
  HELP = 'HELP',
  SHIPPING_COSTS = 'SHIPPING_COSTS',
  MASTERDATA = 'MASTERDATA',
  ORDERS = 'ORDERS',
  DOCUMENTS = 'DOCUMENTS',
  SETUP_PRODUCT_PARTICIPATION = 'SETUP_PRODUCT_PARTICIPATION',
  BACKOFFICE = 'BACKOFFICE',
  SETUP = 'SETUP',
  WARRANTY_PACKAGE = 'WARRANTY_PACKAGE',
}

export const SellerCenterDomainRouteMap = {
  [ AppRoute.DASHBOARD ]: SellerCenterDomain.DASHBOARD,
  [ AppRoute.DOCUMENT ]: SellerCenterDomain.DOCUMENTS,
  [ AppRoute.FEEDBACK ]: SellerCenterDomain.HELP,
  [ AppRoute.PRICING ]: SellerCenterDomain.PRICING,
  [ AppRoute.HELP ]: SellerCenterDomain.HELP,
  [ AppRoute.SHIPPING_COSTS ]: SellerCenterDomain.SHIPPING_COSTS,
  [ AppRoute.MASTER_DATA ]: SellerCenterDomain.MASTERDATA,
  [ AppRoute.ORDERS ]: SellerCenterDomain.ORDERS,
  [ AppRoute.SETUP_PRODUCT_PARTICIPATION ]: SellerCenterDomain.SETUP_PRODUCT_PARTICIPATION,
  [ AppRoute.SETUP ]: SellerCenterDomain.SETUP,
  [ AppRoute.BACKOFFICE ]: SellerCenterDomain.BACKOFFICE,
  [ AppRoute.WARRANTY_PACKAGES ]: SellerCenterDomain.WARRANTY_PACKAGE,
};

export enum SellerCenterService {
  SSSD = 'SSSD',
  SSSA = 'SSSA',
  SSSG = 'SSSG',
  SSSS = 'SSSS',
  SSSP = 'SSSP',
  SSSO = 'SSSO',
}

export enum CommerceDomain {
  MARKETPLACE = 'MARKETPLACE',
  SHOPS = 'SHOPS',
}

// TODO: deprecate CommerceDomain.SHOPS
export const COMMERCE_DOMAIN_PROP = {
  [ CommerceDomain.MARKETPLACE ]: 'productTypes',
  [ CommerceDomain.SHOPS ]:  'legacyShops',
};

export const WILDCARD = '*';

export const AVAILABLE_SHOPS_FOR_DOMAIN = {
  // [ SellerCenterDomain.ORDERS ]: [ SellerCenterShopName.UCOS ],
  [ SellerCenterDomain.DOCUMENTS ]: [ SellerCenterShopName.ACCESSORIES, SellerCenterShopName.GPME, SellerCenterShopName.NCOS, SellerCenterShopName.UCOS ],
  [ SellerCenterDomain.MASTERDATA ]: [ SellerCenterShopName.ACCESSORIES, SellerCenterShopName.GPME, SellerCenterShopName.NCOS ],
  [ SellerCenterDomain.PRICING ]: [ SellerCenterShopName.ACCESSORIES, SellerCenterShopName.GPME ],
};

export const DOMAIN_SERVICES_MAP = {
  [ SellerCenterDomain.DOCUMENTS ]: [ SellerCenterService.SSSD ],
  [ SellerCenterDomain.PRICING ]: [ SellerCenterService.SSSA, SellerCenterService.SSSG ],
  [ SellerCenterDomain.WARRANTY_PACKAGE ]: [ SellerCenterService.SSSG ],
  [ SellerCenterDomain.SHIPPING_COSTS ]: [ SellerCenterService.SSSS ],
  [ SellerCenterDomain.PROFILE ]: WILDCARD,
  [ SellerCenterDomain.SETUP_PRODUCT_PARTICIPATION ]: [ SellerCenterService.SSSP ],
  [ SellerCenterDomain.ORDERS ]: [ SellerCenterService.SSSO ],
};

export const CATEGORY_PRODUCTS_MAP: Record<ProductCategory, ProductType[]> = {
  [ ProductCategory.GENERAL ]: [],
  [ ProductCategory.SERVICE_CONTRACTS ]: [ ProductType.SERVICE_CONTRACT ],
  [ ProductCategory.ACCESSORIES ]: [
    ProductType.ACCESSORIES_FITTING_TYPE,
    ProductType.ACCESSORIES_IN_CAR_TYPE,
    ProductType.ACCESSORIES_NON_CAR_TYPE,
    ProductType.ACCESSORIES_COMPLETE_WHEEL_TYPE,
    ProductType.ACCESSORIES_FITTING_TYPE_PICK_UP_ONLY,
    ProductType.ACCESSORIES_IN_CAR_TYPE_PICK_UP_ONLY,
    ProductType.ACCESSORIES_NON_CAR_TYPE_PICK_UP_ONLY,
  ],
  [ ProductCategory.NCOS ]: [
    ProductType.NCOS_B_STANDARD,
    ProductType.NCOS_D_STANDARD,
    ProductType.NCOS_D_COMVANS,
    ProductType.NCOS_B_COMVANS,
  ],
  [ ProductCategory.UCOS ]: [ ProductType.UCOS_B ],
  [ ProductCategory.TYRES ]: [ ProductType.TYRES ],
  [ ProductCategory.CONNECT ]: [ ProductType.CONNECT_IN_CAR_SERVICE ],
  [ ProductCategory.WALLBOX ]: [ ProductType.WALLBOX ],
  [ ProductCategory.WARRANTY_PACKAGE ]: [ ProductType.WARRANTY_PACKAGE ],
  [ ProductCategory.COMPLETE_CARE ]: [ ProductType.COMPLETE_CARE ],
};

export const SHOPS_WITHOUT_OUTLETS: Array<SellerCenterShopName> = [ SellerCenterShopName.UCOS, SellerCenterShopName.NCOS ];
export const SHOPS_WITH_OUTLETS: Array<SellerCenterShopName> = [ SellerCenterShopName.GPME, SellerCenterShopName.ACCESSORIES  ];

// TODO remove
// only used in profile.service
export enum SSSPShopName {
  ONLINESALES_ACCESSOIRES = 'ONLINESALES-ACCESSOIRES',
  ONLINESALES_NEWCARS = 'ONLINESALES-NEWCARS',
  ONLINESALES_USEDCARS = 'ONLINESALES-USEDCARS',
  ONLINESALES_SERVICECONTRACTS = 'ONLINESALES-SERVICECONTRACTS',
}

const INTERNAL_TO_EXTERNAL_SHOP_NAME = {
  [ SellerCenterDomain.PROFILE ]: { // TODO: split this for SSS-P / SSS-M split
    [ SellerCenterShopName.ACCESSORIES ]: SSSPShopName.ONLINESALES_ACCESSOIRES,
    [ SellerCenterShopName.NCOS ]: SSSPShopName.ONLINESALES_NEWCARS,
    [ SellerCenterShopName.UCOS ]: SSSPShopName.ONLINESALES_USEDCARS,
    [ SellerCenterShopName.GPME ]: SSSPShopName.ONLINESALES_SERVICECONTRACTS,
  },
  // [ SellerCenterDomain.ORDERS ]: {
  //   [ SellerCenterShopName.ACCESSORIES ]: SSSOShopName.ACCESSOIRES,
  //   [ SellerCenterShopName.NCOS ]: SSSOShopName.NEWCARS,
  //   [ SellerCenterShopName.UCOS ]: SSSOShopName.USEDCARS,
  //   [ SellerCenterShopName.GPME ]: SSSOShopName.SERVICECONTRACTS,
  // },
  [ SellerCenterDomain.DOCUMENTS ]: {
    // [ SellerCenterShopName.MARKETPLACE ]: SellerCenterShopName.MARKETPLACE,
    [ SellerCenterShopName.ACCESSORIES ]: SSSDShopName.ACCESSORIES,
    [ SellerCenterShopName.CONNECT ]: SSSDShopName.CONNECT,
    [ SellerCenterShopName.COLLECTION ]: SSSDShopName.COLLECTION,
    [ SellerCenterShopName.NCOS ]: SSSDShopName.NCOS,
    [ SellerCenterShopName.UCOS ]: SSSDShopName.UCOS,
    [ SellerCenterShopName.GPME ]: SSSDShopName.GPME,
  },
};

// TODO remove
export function getInternalShopName(domain: SellerCenterDomain, externalShopName: SSSPShopName | SSSDShopName): SellerCenterShopName {
  const mappings = INTERNAL_TO_EXTERNAL_SHOP_NAME[ domain ];

  if (!mappings) {
    throw new Error(`Can't resolve '${ externalShopName }' to internal shop name `);
  }

  const matchingEntry = Object.entries(mappings)
    .find(entry => entry[ 1 ] === externalShopName);

  if (!matchingEntry || !matchingEntry[ 0 ]) {
    throw new Error(`Can't resolve '${ externalShopName }' to internal shop name `);
  }

  return matchingEntry[ 0 ] as SellerCenterShopName;
}

// TODO remove
export function getExternalShopName<T extends SSSPShopName | SSSDShopName>(domain: SellerCenterDomain, internalShopName: SellerCenterShopName): T {
  const mappings = INTERNAL_TO_EXTERNAL_SHOP_NAME[ domain ];

  if (!mappings || !mappings[ internalShopName ]) {
    throw new Error(`Can't resolve '${ internalShopName }' to external shop name `);
  }

  return mappings[ internalShopName ];
}

// TODO use from SSS-P2
// note: this determines which shops are available in which countries
export interface CountryShopMapping<T> {
  [ SellerCenterMarket.DE ]: {
    [ SellerCenterShopName.GPME ]: T;
    [ SellerCenterShopName.ACCESSORIES ]: T;
  };
  [ SellerCenterMarket.GB ]: {
    [ SellerCenterShopName.ACCESSORIES ]: T;
  };
  [ SellerCenterMarket.FR ]: {
    [ SellerCenterShopName.ACCESSORIES ]: T;
  };
  [ SellerCenterMarket.LU ]: {
    [ SellerCenterShopName.NCOS ]: T;
    [ SellerCenterShopName.UCOS ]: T;
    [ SellerCenterShopName.ACCESSORIES ]: T;
  };
}
