import { ActionMap, reduce } from '@core/store/root.state';
import { Article } from '@generated/sssa/models/article';
import { DeliveryMode } from '@generated/sssa/models/delivery-mode';
import { DeliveryModeUpdate } from '@generated/sssa/models/delivery-mode-update';
import { Price } from '@generated/sssa/models/price';
import { PriceType } from '@generated/sssa/models/price-type';
import { ShippingClass } from '@generated/sssa/models/shipping-class';
import { Action } from '@ngrx/store';
import * as PricingActions from './pricing-accessories.actions';
import { PricingAccessoriesState, PRICING_ACCESSORIES_INITIAL_STATE } from './pricing-accessories.state';

const REDUCER_ACTION_MAP: ActionMap = {
  [ PricingActions.GET_ARTICLES_REQUEST ]: getArticlesRequest,
  [ PricingActions.GET_ARTICLES_SUCCESS ]: getArticlesSuccess,
  [ PricingActions.GET_ARTICLES_FAILURE ]: getArticlesFailure,

  [ PricingActions.UPDATE_ARTICLES_REQUEST ]: updateArticlesRequest,
  [ PricingActions.UPDATE_ARTICLES_SUCCESS ]: updateArticlesSuccess,
  [ PricingActions.UPDATE_ARTICLES_FAILURE ]: updateArticlesFailure,

  [ PricingActions.UPDATE_SURCHARGE_REQUEST ]: updateSurchargeRequest,
  [ PricingActions.UPDATE_SURCHARGE_SUCCESS ]: updateSurchargeSuccess,
  [ PricingActions.UPDATE_SURCHARGE_FAILURE ]: updateSurchargeFailure,

  [ PricingActions.GET_SHIPPING_CLASSES_REQUEST ]: getShippingClassesRequest,
  [ PricingActions.GET_SHIPPING_CLASSES_SUCCESS ]: getShippingClassesSuccess,
  [ PricingActions.GET_SHIPPING_CLASSES_FAILURE ]: getShippingClassesFailure,

  [ PricingActions.UPDATE_SHIPPING_CLASS_REQUEST ]: updateShippingClassRequest,
  [ PricingActions.UPDATE_SHIPPING_CLASS_SUCCESS ]: updateShippingClassSuccess,
  [ PricingActions.UPDATE_SHIPPING_CLASS_FAILURE ]: updateShippingClassFailure,
};

export function pricingAccessoriesReducer(state: PricingAccessoriesState = PRICING_ACCESSORIES_INITIAL_STATE, action: Action): PricingAccessoriesState {
  return reduce(REDUCER_ACTION_MAP, action, state);
}

function getArticlesRequest(state: PricingAccessoriesState): PricingAccessoriesState {
  return {
    ...state,
    articles: {
      error: null,
      isPending: true,
      data: null,
    },
  };
}

function getArticlesSuccess(state: PricingAccessoriesState, action: PricingActions.GetArticlesSuccessAction): PricingAccessoriesState {
  return {
    ...state,
    articles: {
      error: null,
      isPending: false,
      data: action.sellerPriceListContainer,
    },
  };
}

function getArticlesFailure(state: PricingAccessoriesState, action: PricingActions.GetArticlesFailureAction): PricingAccessoriesState {
  return {
    ...state,
    articles: {
      ...state.articles,
      error: action.error,
      isPending: false,
    },
  };
}

function updateArticlesRequest(state: PricingAccessoriesState): PricingAccessoriesState {
  return state;
}

function updateArticlesSuccess(state: PricingAccessoriesState, action: PricingActions.UpdateArticlesSuccessAction): PricingAccessoriesState {
  return {
    ...state,
    articles: {
      ...state.articles,
      data: {
        ...state.articles.data,
        articles: state.articles.data.articles
          .map((article: Article) => {
            const matchingUpdatedArticle = action.articles
              .find((updatedArticle: Article) => article.number === updatedArticle.number);

            return matchingUpdatedArticle
              ? {
                ...article,
                ...matchingUpdatedArticle,
                prices: [
                  ...article.prices.filter((price: Price) => price.type !== PriceType.CUSTOMIZED),
                  ...matchingUpdatedArticle.prices,
                ],
              }
              : article;
          }),
      },
    },
  };
}

function updateArticlesFailure(state: PricingAccessoriesState): PricingAccessoriesState {
  return state;
}

function updateSurchargeRequest(state: PricingAccessoriesState): PricingAccessoriesState {
  return state;
}

function updateSurchargeSuccess(state: PricingAccessoriesState, action: PricingActions.UpdateSurchargeSuccessAction): PricingAccessoriesState {
  return {
    ...state,
    articles: {
      ...state.articles,
      data: {
        ...state.articles.data,
        surcharge: action.surcharge,
      },
    },
  };
}

function updateSurchargeFailure(state: PricingAccessoriesState): PricingAccessoriesState {
  return state;
}

function getShippingClassesRequest(state: PricingAccessoriesState): PricingAccessoriesState {
  return {
    ...state,
    shippingClasses: {
      data: [],
      error: null,
      isPending: true,
    },
  };
}

function getShippingClassesSuccess(state: PricingAccessoriesState, action: PricingActions.GetShippingClassesSuccessAction): PricingAccessoriesState {
  // TODO: This should be replaced by a backend side sorting (DCP shipping costs API) DCPSSS-2922
  const shippingCostsSortFn = (a: ShippingClass, b: ShippingClass): number => {
    try {
      return a.deliveryModes[ 0 ].zoneDeliveryCost.value - b.deliveryModes[ 0 ].zoneDeliveryCost.value;
    } catch {
      // note: silently fail when something goes wrong during sorting
      return 0;
    }
  };

  return {
    ...state,
    shippingClasses: {
      data: [ ...action.shippingClasses ].sort(shippingCostsSortFn),
      error: null,
      isPending: false,
    },
  };
}

function getShippingClassesFailure(state: PricingAccessoriesState, action: PricingActions.GetShippingClassesFailureAction): PricingAccessoriesState {
  return {
    ...state,
    shippingClasses: {
      ...state.shippingClasses,
      error: action.error,
      isPending: false,
    },
  };
}

function updateShippingClassRequest(state: PricingAccessoriesState): PricingAccessoriesState {
  return state;
}

function updateShippingClassSuccess(state: PricingAccessoriesState, action: PricingActions.UpdateShippingClassSuccessAction): PricingAccessoriesState {
  return {
    ...state,
    shippingClasses: {
      ...state.shippingClasses,
      data: state.shippingClasses.data
        .map((shippingClass: ShippingClass) => {
          if (shippingClass.identifier !== action.shippingClassUpdate.identifier) {
            return shippingClass;
          }

          return {
            ...shippingClass,
            deliveryModes: shippingClass.deliveryModes
              .map((deliveryMode: DeliveryMode) => {
                const deliveryModeMatch = action.shippingClassUpdate.deliveryModes
                  .find((deliveryModeUpdate: DeliveryModeUpdate) => deliveryModeUpdate.identifier === deliveryMode.identifier);

                if (!deliveryModeMatch) {
                  return deliveryMode;
                }

                return {
                  ...deliveryMode,
                  sellerDeliveryCost: {
                    currencyIso: deliveryModeMatch.sellerDeliveryCost.currencyIso,
                    value: deliveryModeMatch.sellerDeliveryCost.value,
                  },
                };
              }),
          };
        }),
    },
  };
}

function updateShippingClassFailure(state: PricingAccessoriesState): PricingAccessoriesState {
  return state;
}
