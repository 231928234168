/* tslint:disable */

/**
 * List of available product types.
 */
export enum ProductType {
  ACCESSORIES_COMPLETE_WHEEL_TYPE = 'ACCESSORIES_COMPLETE_WHEEL_TYPE',
  ACCESSORIES_FITTING_TYPE = 'ACCESSORIES_FITTING_TYPE',
  ACCESSORIES_FITTING_TYPE_PICK_UP_ONLY = 'ACCESSORIES_FITTING_TYPE_PICK_UP_ONLY',
  ACCESSORIES_IN_CAR_TYPE = 'ACCESSORIES_IN_CAR_TYPE',
  ACCESSORIES_IN_CAR_TYPE_PICK_UP_ONLY = 'ACCESSORIES_IN_CAR_TYPE_PICK_UP_ONLY',
  ACCESSORIES_NON_CAR_TYPE = 'ACCESSORIES_NON_CAR_TYPE',
  ACCESSORIES_NON_CAR_TYPE_PICK_UP_ONLY = 'ACCESSORIES_NON_CAR_TYPE_PICK_UP_ONLY',
  CENTRAL_DELIVERED_PHYSICAL_PRODUCT = 'CENTRAL_DELIVERED_PHYSICAL_PRODUCT',
  COMPLETE_CARE = 'COMPLETE_CARE',
  CONNECT_IN_CAR_SERVICE = 'CONNECT_IN_CAR_SERVICE',
  INVOICE = 'INVOICE',
  NCOS_B_COMVANS = 'NCOS_B_COMVANS',
  NCOS_B_STANDARD = 'NCOS_B_STANDARD',
  NCOS_D_COMVANS = 'NCOS_D_COMVANS',
  NCOS_D_STANDARD = 'NCOS_D_STANDARD',
  SERVICE = 'SERVICE',
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  SERVICE_CONTRACT_VANS = 'SERVICE_CONTRACT_VANS',
  SMART_DEVICE = 'SMART_DEVICE',
  TYRES = 'TYRES',
  UCOS_B = 'UCOS_B',
  WALLBOX = 'WALLBOX',
  WARRANTY_PACKAGE = 'WARRANTY_PACKAGE'
}
