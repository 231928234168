import { Injectable } from '@angular/core';
import { SetupInterestStatus } from '@app/setup/setup.types';
import * as CompanyOutletSelector2Selectors from '@core/company-outlet-selector-2/store/company-outlet-selector-2.selectors';
import { CATEGORY_PRODUCTS_MAP, getExternalShopName, getInternalShopName, LanguageCode, ProductCategory, SellerCenterDomain, SellerCenterShopName, SSSPShopName } from '@core/config/global.config';
import { I18nService } from '@core/i18n/i18n.service';
import { validateRequestParams } from '@core/interceptors/params-validator';
import { SetProfileAction } from '@core/store/auth/auth.actions';
import * as AuthSelectors from '@core/store/auth/auth.selectors';
import { CurrentStateService } from '@core/store/current-state.service';
import { RootState } from '@core/store/root.state';
import { SetUiLanguagesAction } from '@core/store/session/session.actions';
import { CompanyId, OutletId } from '@core/types/profile.types';
import { Company, CountryCode, Outlet, ProductType, UserEntitlements } from '@core/types/types';
import { ApiConfiguration as SSSPConf } from '@generated/sssp-v2/api-configuration';
import { CountryCode as SSSPCountryCode, Flags, FlagsImplementation, GetProductTypeInterests, OutletInfo, UpdateOrCreateProductTypeInterests } from '@generated/sssp-v2/models';
import { ProductTypeInterestsService as ProductTypeInterestsAPI, ProfileService as ProfileAPI } from '@generated/sssp-v2/services';
import { GetOutletByIdPathParams, GetOutletsByCompanyIdPathParams, OutletsService as OutletAPI } from '@generated/sssp-v2/services/outlets.service';
import { GetProductTypeInterestPathParams, GetProductTypeInterestsForCompanyPathParams, UpdateOrCreateProductTypeInterestPathParams } from '@generated/sssp-v2/services/product-type-interests.service';
import { CreateFlagPathParams, GetFlagsPathParams, GetSellerProfilePathParams } from '@generated/sssp-v2/services/profile.service';
import { Seller } from '@generated/sssp/models/seller';
import { SellerMasterData } from '@generated/sssp/models/seller-master-data';
import { SellerOutlet } from '@generated/sssp/models/seller-outlet';
import { SellerProfileInformation } from '@generated/sssp/models/seller-profile-information';
import { SellerStore } from '@generated/sssp/models/seller-store';
import { StoreMail } from '@generated/sssp/models/store-mail';
import { CreateProtectedMasterDataStoreByGsIdParams, GetProtectedMasterDataByGcIdParams, GetProtectedMasterDataStoresByGsIdParams, GetProtectedSellerByGcAndGsIdParams, GetProtectedSellerProfileInformationParams, ProtectedService as ProfileApiService, UpdateProtectedMasterDataStoreByGsIdParams } from '@generated/sssp/services/protected.service';
import { Store } from '@ngrx/store';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

enum Entity {
  COMPANY = 'COMPANY',
  OUTLETS = 'OUTLETS',
  STORES = 'STORES',
}

export enum Level {
  DETAILS = 'DETAILS',
  OVERVIEW = 'OVERVIEW',
}

@Injectable()
export class ProfileService {
  constructor(
    private readonly profileApiService: ProfileApiService,
    private readonly windowRefService: WindowRefService,
    private readonly currentStateService: CurrentStateService,
    private readonly productTypeInterestsAPI: ProductTypeInterestsAPI,
    private readonly profileAPI: ProfileAPI,
    private readonly outletAPI: OutletAPI,
    private readonly store: Store<RootState>,
    private readonly ssspAPIConf: SSSPConf,
    private readonly i18nService: I18nService,
  ) {
    this.profileApiService.setDomain(this.windowRefService.environment.api.secured.sssp);
    this.ssspAPIConf.rootUrl = this.windowRefService.environment.api.secured.sssp_v2;
  }

  // only used on shop management
  // cannot be migrated, unavailable info/enpoint on sss-p v2
  public getSellerStoreForOutlet(outletId: OutletId): Observable<SellerStore> {
    const params = validateRequestParams<GetProtectedMasterDataStoresByGsIdParams>({
      country: this.companyCountryCode,
      gcId: this.companyId,
      gsId: outletId,
    });

    return this.profileApiService.getProtectedMasterDataStoresByGsId(params)
      .pipe(map((sellerStore: SellerStore) => this.mapStoreToInternalShopName(sellerStore)));
  }

  // only used on shop mananagement
  // cannot be migrated, unavailable info/enpoint on sss-p v2
  public createSellerStoreForOutlet(outletId: OutletId, store: SellerStore): Observable<void> {
    const params = validateRequestParams<CreateProtectedMasterDataStoreByGsIdParams>({
      country: this.companyCountryCode,
      gcId: this.companyId,
      gsId: outletId,
      store: this.mapStoreToExternalShopName(store),
    });

    return this.profileApiService.createProtectedMasterDataStoreByGsId(params);
  }

  // only used on shop mananagement
  // cannot be migrated, unavailable info/enpoint on sss-p v2
  public updateSellerStoreForOutlet(outletId: OutletId, store: SellerStore): Observable<SellerStore> {
    const params = validateRequestParams<UpdateProtectedMasterDataStoreByGsIdParams>({
      country: this.companyCountryCode,
      gcId: this.companyId,
      gsId: outletId,
      store: this.mapStoreToExternalShopName(store),
    });

    return this.profileApiService.updateProtectedMasterDataStoreByGsId(params)
      .pipe(map((sellerStore: SellerStore) => this.mapStoreToInternalShopName(sellerStore)));
  }

  // only used on shop mananagement
  // cannot be migrated, unavailable info/enpoint on sss-p v-2
  public getMasterDataForOutlets(
    shops: Array<SellerCenterShopName>,
    level: Level = Level.OVERVIEW
  ): Observable<SellerMasterData> {
    const params = validateRequestParams<GetProtectedMasterDataByGcIdParams>({
      country: this.companyCountryCode,
      gcId: this.companyId,
      entities: [ Entity.COMPANY, Entity.OUTLETS ],
      level,
      shops: shops.map((shop: SellerCenterShopName) => getExternalShopName<SSSPShopName>(SellerCenterDomain.PROFILE, shop)),
    });

    return this.profileApiService.getProtectedMasterDataByGcId(params);
  }

  // only used on shop mananagement and documents-shops-view
  // cannot be migrated, unavailable info/enpoint on sss-p v2
  public getSellerProfileInformation(country: string, companyId: string): Observable<SellerProfileInformation> {
    const params = validateRequestParams<GetProtectedSellerProfileInformationParams>({
      country: country,
      gcId: companyId,
    });

    return this.profileApiService.getProtectedSellerProfileInformation(params).pipe(first());
  }

  // TODO: error handling as this is not fetched via effects
  // only used on shop management
  // cannot be migrated, unavailable info/enpoint on sss-p v2
  public getSellerOutlet(companyId: CompanyId, outletId: OutletId, countryCode: string): Observable<SellerOutlet> {
    const params = validateRequestParams<GetProtectedSellerByGcAndGsIdParams>({
      country: countryCode,
      gsId: outletId,
      gcId: companyId,
    });

    return this.profileApiService.getProtectedSellerByGcAndGsId(params)
      .pipe(map((seller: Seller): SellerOutlet => seller.outlet));
  }

  public findCompaniesByCountry(countryCode: CountryCode): Company[] {
    const companies = this.currentStateService.get(AuthSelectors.allCompanies);
    return companies.filter(i => i.countryCode === countryCode);
  }

  public fetchOutletsByCompany(gcid: string, countryCode: SSSPCountryCode, filterOutlets = false): Observable<Outlet[]> {
    const params = validateRequestParams<GetOutletsByCompanyIdPathParams>({
      country: countryCode,
      companyId: gcid,
      filter: filterOutlets,
    });

    return this.outletAPI.getOutletsByCompanyId(params)
      .pipe(
        first(),
        map((res) => this.mapOutlets(res, gcid, countryCode))
      );
  }

  public fetchProfile(): Observable<UserEntitlements> {
    const params = validateRequestParams<GetSellerProfilePathParams>(null);
    return this.profileAPI.getSellerProfile(params)
      .pipe(
        first(),
        map(this.registerProfile, this),
        map(this.registerUiLanguages.bind(this)),
      );
  }

  public getProductTypeInterest(country: SSSPCountryCode, companyId: string, outletId: string): Observable<Record<string, GetProductTypeInterests>> {
    const params = validateRequestParams<GetProductTypeInterestPathParams>({
      country: country,
      companyId: companyId,
      outletId: outletId,
    });

    return this.productTypeInterestsAPI.getProductTypeInterest(params).pipe(first());
  }

  public getProductTypeInterestByCompany(country: SSSPCountryCode, companyId: string): Observable<Record<string, GetProductTypeInterests>> {
    const params = validateRequestParams<GetProductTypeInterestsForCompanyPathParams>({
      country: country,
      companyId: companyId,
    });

    return this.productTypeInterestsAPI.getProductTypeInterestsForCompany(params).pipe(first());
  }

  public saveInterestInformation(outlet: Outlet, interestProductCategory: ProductCategory, interestStatus: SetupInterestStatus): Observable<UserEntitlements> {
    const availableProductTypes = this.getAvailableCountryProductTypes();
    const countryCode = outlet.countryCode;

    const products = CATEGORY_PRODUCTS_MAP[interestProductCategory]
      .filter(productName => availableProductTypes.includes(productName));

    const outletInterest: UpdateOrCreateProductTypeInterests = {
      outletId: outlet.outletId,
      companyId: outlet.companyId,
      productTypeInterests: products
        .reduce((accum, product) => {
          accum[product] = interestStatus;
          return accum;
        }, {}),
    };

    return this.updateOrCreateProductTypeInterest(countryCode, outletInterest)
      .pipe(
        first(),
        switchMap(() => this.fetchProfile())
      );
  }

  public updateOrCreateProductTypeInterest(
    countryCode: SSSPCountryCode,
    outletInterest: UpdateOrCreateProductTypeInterests,
  ): Observable<void> {
    const params = validateRequestParams<UpdateOrCreateProductTypeInterestPathParams>({
      country: countryCode,
      companyId: outletInterest.companyId,
      outletId: outletInterest.outletId,
    });

    return this.productTypeInterestsAPI.updateOrCreateProductTypeInterest({ ...params, body: outletInterest })
      .pipe(first());
  }

  public getAvailableCountryProductTypes(): ProductType[] {
    const { countryCode } = this.currentStateService.get(CompanyOutletSelector2Selectors.selectedCompany);

    return this.currentStateService.get<ProductType[]>(AuthSelectors.availableProductTypes, { countryCode: countryCode });
  }

  public getProfileFlags(): Observable<FlagsImplementation> {
    return this.profileAPI
      .getFlags(validateRequestParams<GetFlagsPathParams>(null))
      .pipe(
        first(),
        map(res => res.flags)
      );
  }

  public setHTMLFormatFlag(hasRead: boolean): Observable<void> {
    const params = validateRequestParams<CreateFlagPathParams>(null);
    const body: Flags =  { flags: { NEW_HTML_FORMAT: hasRead } };

    return this.profileAPI.createFlag({ ...params, body }).pipe(first());
  }

  public getOutletById(companyId: string, outletId: string): Observable<Outlet> {
    const params = validateRequestParams<GetOutletByIdPathParams>({ companyId: companyId, outletId: outletId });

    return this.outletAPI.getOutletById(params)
      .pipe(
        first(),
        map((outletInfo) => ({
          ...outletInfo,
          companyId: companyId,
          outletId: outletId,
        }))
      );
  }

  private mapStoreToExternalShopName(sellerStore: SellerStore): SellerStore {
    return {
      ...sellerStore,
      mails: sellerStore.mails.map((mail: StoreMail) => ({
        ...mail,
        shop: getExternalShopName<SSSPShopName>(SellerCenterDomain.PROFILE, mail.shop as SellerCenterShopName),
      })),
    };
  }

  private mapStoreToInternalShopName(sellerStore: SellerStore): SellerStore {
    return {
      ...sellerStore,
      mails: sellerStore.mails
        .map((mail: StoreMail) => ({
          ...mail,
          shop: getInternalShopName(SellerCenterDomain.PROFILE, mail.shop as SSSPShopName),
        })),
    };
  }

  // TODO: I think we should not have this lookup here. We should always pass this params to the methods.
  // https://jira.mercedes-benz.io/browse/DCPSSS-2911
  private get companyId(): CompanyId {
    return this.currentStateService.get<CompanyId>(AuthSelectors.companyId);
  }

  private get companyCountryCode(): string {
    return this.currentStateService.get<string>(AuthSelectors.companyCountryCode);
  }

  private registerProfile(profile: UserEntitlements): UserEntitlements {
    let commerceDomains = [];

    if (isEmpty(profile)) {
      throw new Error('Invalid profile');
    }

    this.store.dispatch(new SetProfileAction(profile));

    commerceDomains = this.currentStateService.get(AuthSelectors.commerceDomains);

    if (commerceDomains.length === 0) {
      throw new Error('No commerce domains available');
    }

    return profile;
  }

  private registerUiLanguages(profile: UserEntitlements): void {
    const languages = Object.values(profile.countries)
      .flatMap(country => country.languages.map((langCode) => langCode.toLowerCase()))
      .filter((lang, idx, self) => self.indexOf(lang) === idx);

    this.store.dispatch(new SetUiLanguagesAction(languages as LanguageCode[]));
    this.i18nService.validateSelectedLanguage();
  }

  // TODO duplicate from auth selector
  private mapOutlets(outlets: Record<string, OutletInfo>, companyId: string, countryCode: string): Outlet[] {
    return Object.entries(outlets)
      .map(([ id, outlet ]) => ({
        outletId: id,
        companyId,
        countryCode: countryCode as SSSPCountryCode,
        ...outlet,
      }));
  }
}
